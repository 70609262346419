import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './locales/en-US.json'
import id from './locales/id.json'
import ph from './locales/ph.json'
import th from './locales/th.json'
import vi from './locales/vi.json'
import zhCN from './locales/zh-cn.json'
import zhTW from './locales/zh-tw.json'

import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'

i18n
  .use(ChainedBackend)
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'zhTW',
    resources: {
      zhCN: {
        translation: zhCN,
      },
      zhTW: {
        translation: zhTW,
      },
      enUS: {
        translation: enUS,
      },
      ph: {
        translation: ph,
      },
      th: {
        translation: th,
      },
      vi: {
        translation: vi,
      },
      id: {
        translation: id,
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  })

export default i18n
