import { useMemo } from 'react'

import { createConsumer, getConfig } from '@rails/actioncable'

const cables = {}

export default function useCable(query?: string) {
  return useMemo(() => {
    const url = getConfig('url')
    if (query) {
      if (!cables[query]) {
        if (query === 'default') {
          cables[query] = createConsumer(url)
        } else {
          cables[query] = createConsumer(`${url}?${query}`)
        }
      }
      return cables[query]
    } else {
      return null
    }
  }, [query])
}
