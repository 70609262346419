import React, { useEffect, useState } from 'react'

import styled, { keyframes } from 'styled-components'

const opencard = (rotate) => keyframes`
  0% {
    transform: ${`rotateY(${rotate - 90}deg)`};
  }
  100% {
    transform: ${`rotateY(${rotate}deg)`};
  }
`

const Container = styled.div`
  animation: ${(props) => opencard(props.rotate)} 1s;
  display: ${(props) => (props.on ? 'block' : 'none')};
`

const CardImage = styled.img``

const Card = ({
  card,
  on,
  selected = true,
  soundOn = false,
  rotate = 0,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  if (!card) return null

  return (
    <Container on={on ? 'on' : undefined} {...rest} rotate={rotate}>
      <CardImage src={require(`images/richman/cards/${card}.png`)} />
    </Container>
  )
}

export default Card
