import { Howl } from 'howler'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import RichmanCard from 'components/richman/Card'
import './iconfont.js'

const opencard = keyframes`
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`

const Container = styled.div`
  animation: ${opencard} 1s;
  display: ${(props) => (props.on ? 'block' : 'none')};
  width: ${(props) => (props.width ? `${props.width}px` : '10vw')};
  height: ${(props) => (props.width ? `${props.width * 1.4}px` : '12.25vw')};
`

const Icon = styled.div`
  opacity: ${(props) => (props.selected ? 1 : 0.3)};
  width: ${(props) => (props.width ? `${props.width}px` : '10vw')};
  height: ${(props) => (props.width ? `${props.width * 1.4}px` : '12.25vw')};
  vertical-align: -0.15em;
  overflow: hidden;
`

const SvgIcon = styled.svg`
  opacity: ${(props) => (props.selected ? 1 : 0.3)};
  width: ${(props) => (props.width ? `${props.width}px` : '10vw')};
  height: ${(props) => (props.width ? `${props.width * 1.4}px` : '12.25vw')};
  vertical-align: -0.15em;
  overflow: hidden;
`

const Card = ({ card, on, selected = true, soundOn = false, ...rest }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  if (!card) return null

  const style = {
    width: rest.width ? `${rest.width - 4}px` : '10vw',
    height: rest.width ? `${rest.width * 1.4 - 4}px` : '12.25vw',
  }

  return (
    <Container on={on ? 'on' : undefined} {...rest}>
      <Icon className="icon" selected={open} aria-hidden="true" {...rest}>
        <img src={require(`images/cards/${card}.png`)} style={style} />
      </Icon>
    </Container>
  )
}

const OldCard = ({ card, on, selected = true, soundOn = false, ...rest }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(selected)
  }, [selected])

  if (!card) return null

  return (
    <Container on={on ? 'on' : undefined} {...rest}>
      <SvgIcon className="icon" selected={open} aria-hidden="true" {...rest}>
        <use xlinkHref={`#icon${card}`}></use>
      </SvgIcon>
    </Container>
  )
}

const CardSwitch = (props) => {
  const [site, setSite] = useState('')

  useEffect(() => {
    const name = document
      .querySelector('meta[name="site"]')
      ?.getAttribute('content')
    setSite(name)
  }, [])

  if (site === 'richman') {
    return <RichmanCard {...props} />
  } else if (site !== '' && props.old) {
    return <OldCard {...props} />
  } else {
    return <Card {...props} />
  }

  return null
}

export default CardSwitch
